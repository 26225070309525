<template>
  <a-drawer width="75%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :maskClosable="formType == 1 ? false : true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Business Type:" prop="businessType" >
<!--        <a-select v-model="form.businessType" placeholder="-Select Business Type-" @change="changeBusinessType">-->
<!--          <a-select-option :key="item.id" v-for="(item,index) in BusinessTypeList">{{ item.name }}</a-select-option>-->
<!--        </a-select>-->

        <a-select
          placeholder="-Business Type-"
          style="width: 100%"
          showSearch
          @change="changeBusinessType"
          v-model="form.businessType"
          optionFilterProp="title"
        >
          <a-select-option v-for="item in BusinessTypeList" :key="item.id" :value="item.id" :title="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      产品类型：0-一次性 1-电池 2-汽化器-->
      <a-form-model-item label="Product Type:" prop="productTypeId" >
<!--        <a-select v-model="form.productType" placeholder="-Select Product Type-" @change="changeProductType">-->
<!--          <a-select-option :key="0">Disposable</a-select-option>-->
<!--          <a-select-option :key="1">Batteries</a-select-option>-->
<!--          <a-select-option :key="2">Vaporizer</a-select-option>-->
<!--        </a-select>-->
        <a-select
          placeholder="-Product Type-"
          style="width: 100%"
          showSearch
          v-model="form.productTypeId"
          optionFilterProp="title"
          @change="changeProductType"
        >
          <a-select-option v-for="item in productTypeList" :key="item.id" :value="item.id" :title="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

<!--      <a-form-model-item label="Is it customizable:" prop="customizable" >-->
<!--        <a-select v-model="form.customizable" placeholder="-Select Customizable Type-">-->
<!--          <a-select-option :key="0">No</a-select-option>-->
<!--          <a-select-option :key="1">Yes</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="is Tank:" prop="isTank" >-->
<!--        <a-select v-model="form.isTank" placeholder="-Select Tank-">-->
<!--          <a-select-option :key="0">No</a-select-option>-->
<!--          <a-select-option :key="1">Yes</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="Is it a one-time use" prop="productType" >-->
<!--        <a-select v-model="form.productType" placeholder="-Select Disposable-">-->
<!--          <a-select-option :key="0">Yes</a-select-option>-->
<!--          <a-select-option :key="1">No</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      激活类型：0-按钮已激活 1-吸气激活-->
      <a-form-model-item label="Activation Type:" prop="activationType" >
        <a-select v-model="form.activationType" placeholder="-Select Activation Type-">
          <a-select-option :key="0">Button Activated</a-select-option>
          <a-select-option :key="1">Inhale Activated</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Product Series Name:" prop="goodsName" >
        <a-input v-model="form.goodsName" :maxLength="50" placeholder="" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.goodsName && form.goodsName.length) || 0) }}/50</div>
      </a-form-model-item>
      <a-form-model-item label="Product Series Introduction:" prop="content" >
        <a-textarea
          v-model="form.content"
          placeholder=""
          :maxLength="1000"
          auto-size
        />
        <div style="display: flex;justify-content: flex-end">{{ ((form.content && form.content.length) || 0) }}/1000</div>
      </a-form-model-item>

      <a-form-model-item label="Specifications and Models:">
        <div v-for="(item,index) in skuList" :key="index" style="display:flex;align-items: center; justify-content: space-between;background: #F2F2F2;padding: 0 10px;margin-bottom: 20px;">
          <div>
            <a-form-model layout="horizontal">
              <a-form-model-item label="Available Compatibility:" >
                <a-radio-group v-model="item.type">
                  <a-radio :value="0">
                    OMNI NFC
                  </a-radio>
                  <a-radio :value="1">
                    OMNI Hub
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>

              <a-form-model-item label="Available Tank Size:" v-if="form.isTank === 1">
                <a-radio-group v-model="item.tankSizeId" @change="changeTankSize($event,item)">
                  <a-radio :value="ite.id" v-for="(ite, inex) in TankSizeList">
                    {{ ite.name }}
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>

              <a-form-model-item label="Resistance Value:" required>
                <a-input-number  placeholder="" v-model="item.resistanceValue" :min="0.1" :step="0.1" /><span style="margin-left: 5px">Ω</span>
              </a-form-model-item>

              <a-form-model-item label="Max Output Voltage:" required>
                <a-input-number  placeholder="" v-model="item.maxOutputVoltage" :min="0.4" :step="0.1" /><span style="margin-left: 5px">V</span>
              </a-form-model-item>

              <a-form-model-item label="Other Option:">
                <a-select v-model="item.otherOptionId" placeholder="-Select An Option-" style="width: 100%;" @change="changeOtherOption($event,item)">
                  <a-select-option :key="ite.id" v-for="(ite,idx) in OtherOptionList" >{{ ite.name }}</a-select-option>
                </a-select>Please go to the <a @click.stop="gotoParams()">Public Info Mgmt</a> page to create new option
              </a-form-model-item>

              <a-form-model-item label="Product Model #:" required>
                <a-input  placeholder="" v-model="item.productModel" :maxLength="50" /><div style="display: flex;justify-content: flex-end">{{ ((item.productModel && item.productModel.length) || 0)}}/50</div>
              </a-form-model-item>

              <a-form-model-item label="Variant Code #:" required>
                <a-input  placeholder="" v-model="item.variantCode" :maxLength="50"/><div style="display: flex;justify-content: flex-end">{{ ((item.variantCode && item.variantCode.length) || 0)}}/50</div>
              </a-form-model-item>

              <a-form-model-item label="Marketing Name:" required>
                <a-input placeholder="" v-model="item.name" :maxLength="50"/><div style="display: flex;justify-content: flex-end">{{ ((item.name && item.name.length) || 0)}}/50</div>
              </a-form-model-item>

              <a-form-model-item label="Product Picture:" required>
<!--                <a-button type="primary">Upload Picture</a-button>-->
                <a-upload
                  name="file"
                  :multiple="true"
                  :show-upload-list="false"
                  :action="tupianshangchuanDizhi"
                  :headers="headers"
                  @change="handleChange($event,item)"
                >
                  <a-button type="primary" style="margin-right: 10px;">Upload Picture</a-button>Recommended size of picture:490 pixels * 490 pixels, supporting JPG and PNG formats.
                </a-upload>

                <div style="display:flex;flex-wrap: wrap;margin-top: 10px;" v-if="item.url">
                  <div style="display: flex;flex-direction: column;justify-content: space-between" v-for="(ite,inde) in item.url.split(',')">
                    <img :src="ite"  style="width: 200px;height: 200px;margin-right: 5px;margin-bottom: 5px;" />
                    <div style="display:flex;align-items: center;margin-top: 5px;justify-content: center">
                      <a @click.stop="deleteGoodsUrls(inde,item)">Delete</a>
                      <a style="margin-left: 20px;" @click.stop="setCaverImage(ite,item)" v-if="item.picture != ite || !item.picture ">Set as cover</a>
                    </div>
                  </div>
                </div>
              </a-form-model-item>

              <a-form-model-item label="Status:">
                <a-radio-group v-model="item.status">
                  <a-radio :value="1">
                    Enable
                  </a-radio>
                  <a-radio :value="0">
                    Disable
                  </a-radio>

                </a-radio-group>

              </a-form-model-item>
            </a-form-model>
          </div>
          <div style="display: flex;flex-direction: column;">
            <a-button type="primary" v-if="index === 0" @click.stop="addSkuData">+Add</a-button>
            <a-button type="danger" v-if="index !== 0" style="margin-top: 10px" @click.stop="deleteSkuData(item,index)">
              -Delete
            </a-button>
          </div>
        </div>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGoods, addGoods, updateGoods } from '@/api/cigarette/goods'
import Editor from '@/components/Editor'
import { listConfig } from '@/api/cigarette/config'
import {tupianshangchuanDizhi} from '@/utils/request';
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      tupianshangchuanDizhi:tupianshangchuanDizhi,
      submitLoading: false,
      formTitle: '',
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      // 表单参数
      formTwo:{},
      form: {
        id: null,

        createTime: null,

        remark: null,

        businessType: null,

        businessTypeName: null,

        productType: null,

        activationType: null,

        goodsName: null,

        content: null,
        productTypeId: null,
        isTank:1,
        customizable: 1,
        picture: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        goodsName: [
          { required: true, message: 'Product Series Name cannot be empty', trigger: 'blur' }
        ],
        businessType: [{
          required: true, message: 'Business Type cannot be empty'
        }],
        productTypeId:[{
          required: true, message: 'Product Type cannot be empty'
        }],
        productType: [{
          required: true, message: 'Disposable cannot be empty'
        }],
        isTank: [
          {
            required: true, message: 'isTank cannot be empty'
          }
        ],
        activationType: [{
          required: true, message: 'Activation Type cannot be empty'
        }],
        content:[
          { required: true, message: 'Product Series Introduction cannot be empty', trigger: 'blur' }
        ],
      },
      BusinessTypeList: [],
      productTypeList: [],
      TankSizeList: [],
      OtherOptionList: [],
      skuList: [],
      singleItemData: {
        id:null,
        type: 0,
        tankSizeId: null,
        tankSizeValue: null,
        resistanceValue: 0,
        maxOutputVoltage: 0,
        otherOptionId: null,
        otherOptionValue: null,
        productModel: null,
        variantCode: null,
        name: null,
        url: null,
        status: 1,
        picture: null,
        goodsId: null,
        productType: null,
        productTypeName: null
      },
      headers:{}
    }
  },
  filters: {
  },
  created () {
    this.headers = {
      "Authorization" : "Bearer " + this.$store.getters.token
    }
    this.getList();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    setCaverImage(ite,item) {
      this.$set(item,'picture',ite)
    },
    deleteGoodsUrls(index,item) {
     let urls = item.url.split(',');
     if(urls[index] == item.picture) {
       item.picture = null;
     }
      urls.splice(index,1);
      item.url = urls.join(',')
    },
    handleChange(e,item) {
      console.log(e)
      console.log(item);
      if(item.url) {
        item.url +=(',' + e.file.response.data)
      } else {
        item.url = e.file.response.data
      }
      // e.file.response.data
    },
    changeOtherOption(e,item) {
      if(e) {
        let shuju = this.OtherOptionList.filter(i => i.id == e);
        if(shuju.length) {
          this.$set(item, 'otherOptionValue' ,shuju[0].name)
        }
      }
    },
    changeTankSize(e,item) {
      console.log(e.target.value)
      if(e.target.value) {
        let shuju = this.TankSizeList.filter(i => i.id == e.target.value);
        if(shuju.length) {
          console.log(shuju)
          this.$set(item, 'tankSizeValue' ,shuju[0].name)
        }
      }
    },
    changeBusinessType(e) {
      console.log(e);
      console.log(this.BusinessTypeList);
      if(e == '421634418889875456') {
        //花类
        this.form.customizable = 0;
      } else if(e == '421627053432004609') {
        //cbd
        this.form.customizable = 1;
      } else if(e == '421625733035745280') {
        //尼古丁 ends
        this.form.customizable = 0;
      }
      if(e) {
       let shuju = this.BusinessTypeList.filter(i => i.id == e);
       if(shuju.length) {
         this.form.businessTypeName = shuju[0].name;
       }
      }
    },
    changeProductType(e) {
      console.log(e)
      console.log('430050194885951489' + '一次性产品')
      console.log('430050132382433280' + '电池产品');
      if(e == '430050194885951489') {
        this.form.isTank = 1;
        this.form.productType = 0;
      } else if(e == '430050132382433280') {
        this.form.isTank = 0;
        this.form.productType = 1;

      }
      // this.productTypeList.forEach(i=>i.productType = e);
      if(e) {
        let shuju = this.productTypeList.filter(i => i.id == e);
        if(shuju.length) {
          this.form.productTypeName = shuju[0].name;
        }
      }
    },
    gotoParams() {
      this.$router.push('/systemSettins/publicinfo')
    },
    deleteSkuData(item ,index) {
      if(item.id){
        //进行删除操作
        this.skuList.splice(index,1);
      } else {
        this.skuList.splice(index,1);
      }
    },
    getList () {
      listConfig({
        type: 0,
        status: 1
      }).then(res => {
        if (res.success) {
          this.BusinessTypeList = res.data;
        }
      })

      listConfig({
        type: 1,
        status: 1
      }).then(res => {
        if (res.success) {
          this.TankSizeList = res.data;
        }
      })

      listConfig({
        type: 2,
        status: 1
      }).then(res => {
        if (res.success) {
          this.OtherOptionList = res.data;
        }
      })

      listConfig({
        type: 6,
        status: 1
      }).then(res => {
        if (res.success) {
          this.productTypeList = res.data;
        }
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        businessType: null,
        businessTypeName: null,
        productType: null,
        activationType: null,
        goodsName: null,
        content: null,
        productTypeId: null,
        isTank: 1,
        productTypeName: null,
        customizable: 1,
        picture:null
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.skuList = [{
        id:null,
        type: 0,
        tankSizeId: null,
        tankSizeValue: null,
        resistanceValue: 0,
        maxOutputVoltage: 0,
        otherOptionId: null,
        otherOptionValue: null,
        productModel: null,
        variantCode: null,
        name: null,
        url: null,
        status: 1,
        picture: null,
        goodsId: null,
        productType: null,
        picture:null,
      }];
      this.open = true
      this.formTitle = 'Create Omni Product'
    },
    addSkuData() {
      this.skuList.unshift({
        id: null,
        type: 0,
        tankSizeId: null,
        tankSizeValue: null,
        resistanceValue: 0,
        maxOutputVoltage: 0,
        otherOptionId: null,
        otherOptionValue: null,
        productModel: null,
        variantCode: null,
        name: null,
        url: null,
        status: 1,
        picture: null,
        goodsId: null,
        productType: null,
        picture: null
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      console.log(row);
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGoods({"id":id}).then(response => {
        this.form = response.data;
        this.skuList = response.data.goodsSkuList;
        this.open = true
        this.formTitle = 'Modify Omni Product'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form);
      console.log(this.skuList);
     let everyName= this.skuList.every(i => i.picture === null);
     if(everyName) {
       this.$message.error("Set Cover Picture")
       return;
     }
      this.form.picture = this.skuList[this.skuList.length - 1].picture;
      this.form.goodsSkuList = this.skuList;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGoods(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGoods(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
