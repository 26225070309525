import request from '@/utils/request'


// 查询新-产品列表
export function listGoods(query) {
  return request({
    url: '/cigarette/goods/list',
    method: 'get',
    params: query
  })
}

// 查询新-产品分页
export function pageGoods(query) {
  return request({
    url: '/cigarette/goods/page',
    method: 'get',
    params: query
  })
}

// 查询新-产品详细
export function getGoods(data) {
  return request({
    url: '/cigarette/goods/detail',
    method: 'get',
    params: data
  })
}

// 新增新-产品
export function addGoods(data) {
  return request({
    url: '/cigarette/goods/add',
    method: 'post',
    data: data
  })
}

// 修改新-产品
export function updateGoods(data) {
  return request({
    url: '/cigarette/goods/edit',
    method: 'post',
    data: data
  })
}

// 删除新-产品
export function delGoods(data) {
  return request({
    url: '/cigarette/goods/delete',
    method: 'post',
    data: data
  })
}
